import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'
import { useStaticQuery, graphql } from 'gatsby'

const Container = styled.div({
  display: 'flex',
  alignItems: 'center',
  flexShrink: 0,
  fontSize: 18,
  color: '#333',
})

export const StyledLogo = styled.div({
  marginRight: 8,
  height: 36,
  fill: 'currentColor',
})

export default function LogoTitle(props) {
  const { file } = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "logo.png" }) {
          publicURL
      }
    }
  `)

  return (
    <Container className={props.className}>
      {!props.noLogo && !!file && (
        <img
          alt="MobileUI Logo"
          style={{ marginRight: 10, maxWidth: props.width }}
          src={file.publicURL}
        />
      )}
      {props.title}
    </Container>
  )
}

LogoTitle.propTypes = {
  noLogo: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
}
