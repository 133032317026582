import React from 'react';
import styled from '@emotion/styled';
// import {ApolloIcon} from '@apollo/space-kit/icons/ApolloIcon';
// import {ReactComponent as Docs} from '../../assets/docs.svg';
import { graphql, useStaticQuery } from "gatsby"

const Container = styled.div({
  display: 'flex',
  alignItems: 'center',
  flexShrink: 0,
  fontSize: 18,
  color: '#333',
})

export const StyledLogo = styled.div({
  marginRight: 8,
  height: 36,
  fill: 'currentColor',
})

export default function Logo(props) {
  const { file } = useStaticQuery(graphql`
      {
          file(relativePath: { eq: "logo.png" }) {
              publicURL
          }
      }
  `)

  return (
    <Container className={props.className}>
      {!props.noLogo && !!file && (
        <img
          alt="MobileUI Logo"
          style={{ marginRight: 10, maxWidth: 40  }}
          src={file.publicURL}
        />
      )}
      {"MobileUI"}
    </Container>
  );
}
